import dimensions from "../../constants/dimensions"
import styled from "styled-components"
import breakpoints from "../../constants/breakpoints"
import Img from "gatsby-image"

export const InfoSection = styled.section`
  display: flex;
  flex-direction: column;
  margin: 1rem auto 2rem auto;
  padding: 0 1rem;
  max-width: ${dimensions.maxWidth}px;

  @media (max-width: ${breakpoints.large}px) {
    margin: 1rem auto 2rem auto;
    padding: 0 rem;
  }

  h2 {
    margin-bottom: 0rem;
  }

  h3 {
    margin-bottom: 0;
  }

  li {
    p {
      margin: 0;
    }
  }

  a {
    margin: 0 5px;

    text-decoration: none;
    color: darkgreen;

    &:hover,
    &:focus {
      text-decoration: underline dashed darkgreen;
    }

    &:visited {
      color: darkgreen;
    }
  }

  .tel {
    color: black !important;
    content: "☎";
  }
`

export const StyledImage = styled(Img)`
  width: 100%;
  height: 400px;

  #third {
    opacity: 0.5;
  }
`

export const PictureSection = styled.section`
  margin-bottom: 1rem;

  display: grid;
  grid-template-columns: repeat(${props => props.imageCount}, 1fr);
  grid-template-rows: auto 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;

  @media (max-width: ${breakpoints.small}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 4fr;

    .first-wrapper {
      grid-area: 1 / 1 / 2 / 2;
    }
    .first-name {
      grid-area: 2 / 1 / 3 / 2;
    }
    .second-wrapper {
      grid-area: 1 / 2 / 2 / 3;
    }
    .second-name {
      grid-area: 2 / 2 / 3 / 3;
    }
    .third-wrapper {
      grid-area: 3 / 1 / 4 / 2;
    }
    .third-name {
      grid-area: 4 / 1 / 5 / 2;
    }
  }

  @media (max-width: ${breakpoints.extraSmall}px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto 6fr;

    .first-wrapper {
      grid-area: 1 / 1 / 2 / 2;
    }
    .first-name {
      grid-area: 2 / 1 / 3 / 2;
    }
    .second-wrapper {
      grid-area: 3 / 1 / 4 / 2;
    }
    .second-name {
      grid-area: 4 / 1 / 5 / 2;
    }
    .third-wrapper {
      grid-area: 5 / 1 / 6 / 2;
    }
    .third-name {
      grid-area: 6 / 1 / 7 / 2;
    }

    .thore-wrapper {
      grid-area: 1 / 1 / 2 / 2;
    }
    .thore-name {
      grid-area: 2 / 1 / 3 / 2;
    }
    .fourth-wrapper {
      grid-area: 3 / 1 / 4 / 2;
    }
    .fourth-name {
      grid-area: 4 / 1 / 5 / 2;
    }
  }

  p {
    margin-top: 0.5rem;
    text-align: center;
    height: 1rem;
  }

 

  .first {
    height: 450px;

    // margin-top: -50px;
  }

  .first-wrapper {
    overflow: hidden;
    height: 400px;
  }

  .second {
    height: 475px;

    margin-top: -50px;
  }

  .second-wrapper {
    overflow: hidden;
    height: 400px;
  }
  
   .third {
    height: 500px;

    margin-top: -50px;
  }

  .third-wrapper {
    overflow: hidden;
    height: 400px;
  }
`

export const FilePreview = styled.div`
  img {
    object-fit: contain !important;
    width: unset !important;
  }

  a {
    div {
      // PDF Preview Border
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 5px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
  }
`

export const VideoSection = styled.div`
  display: flex;
  flex-direction: column;

  .video-wrapper {
    display: flex;
    justify-content: center;

    width: 100%;
    height: calc(100vh - ${dimensions.headerHeight}px);

    video {
      width: auto;
    }
  }
`
