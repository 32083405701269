import Layout from "../components/Layout"
import React from "react"
import {
  FilePreview,
  InfoSection,
  PictureSection,
  StyledImage,
  VideoSection,
} from "../components/AnsprechpersonenPage/styled"
import { graphql } from "gatsby"

export default function AnsprechpersonenPage({ data, location }) {
  return (
    <Layout
      title="Gau Alt-Burgund | Vertrauenspersonen"
      description="Vertrauenspersonen im Alt-Burgund"
      location={location}
    >
      <InfoSection>
        <h2>An wen kann ich mich wenden, wenn ich Sorgen habe?</h2>
        <p>
          Du hast ein Verhalten beobachtet, das du nicht gut findest? Dir wurde
          etwas anvertraut? Dir ist selbst etwas passiert? Du hast einfach ein
          komisches Gefühl? Das darfst du ernst nehmen und mit jemandem darüber
          reden. Wichtig ist, zunächst Ruhe zu bewahren und nicht vorschnell zu
          handeln. Wenn du magst wende dich an folgende Menschen, sie kennen
          sich aus und stehen dir zur Seite!
        </p>

        <PictureSection imageCount={2}>
          <div className={"first-wrapper"}>
            <StyledImage
              className={"first"}
              fluid={data.louise.childImageSharp.fluid}
            />
          </div>
          <div className={"second-wrapper"}>
            <StyledImage
              className={"second"}
              fluid={data.till.childImageSharp.fluid}
            />
          </div>

          <p className={"first-name"}>Lou Doré</p>
          <p className={"second-name"}>Till Schöllhammer</p>
        </PictureSection>

        <PictureSection imageCount={2}>
          <div className="third-wrapper">
            <StyledImage
              className={"third"}
              fluid={data.inge.childImageSharp.fluid}
            />
          </div>
          <div className={"fourth-wrapper"}>
            {/*<StyledImage*/}
            {/*  className={"fourth"}*/}
            {/*  fluid={data.fourth.childImageSharp.fluid}*/}
            {/*/>*/}
          </div>

          <p className={"third-name"}>Inge Böhm</p>
          <p className={"fourth-name"}>Sarah Fangmann</p>
        </PictureSection>

        <ul>
          <li>
            <p>Ansprechpersonen des Gaues Alt-Burgund:</p>
            <p>
              →
              <a
                href={"mailto:ansprechperson@altburgund.de"}
                rel={"noopener noreferrer"}
              >
                ✉ ansprechperson@altburgund.de
              </a>
            </p>
          </li>
          <li>
            <p>VCP-Vertrauenspersonen auf Landesebene: </p>
            <p>
              →
              <a
                target={"_blank"}
                href={"https://vcp-rps.de/kontakt/vertrauenspersonen/"}
                rel={"noopener noreferrer"}
              >
                https://vcp-rps.de/kontakt/vertrauenspersonen/
              </a>
            </p>
            <p>
              {" "}
              → Landesbüro:
              <a
                className={"tel"}
                href={"tel:06233-21955"}
                rel={"noopener noreferrer"}
              >
                ☎ 06233-21955
              </a>
            </p>
          </li>
          <li>
            eine andere Person deines Vertrauens – zum Beispiel dein*e
            Sippenführer*in, Stammesführung oder deine Eltern
          </li>
        </ul>

        <VideoSection>
          <h2>Vorstellung</h2>
          <div className={"video-wrapper"}>
            <video
              src="/ansprechpersonen.mp4"
              controls
              height={"100%"}
              // poster="thumbnail.jpg"
            >
              This video cannot be played in your current browser.
              <br />
              View the download version at <a href="URL">Link address</a>
            </video>
          </div>
        </VideoSection>

        <h2>Unser Schutzkonzept</h2>
        <p>Unser aktuelles Schutzkonzept:</p>
        <FilePreview>
          <a href={"/schutzkonzept.pdf"} target={"_blank"}>
            <StyledImage
              className={"third"}
              fixed={data.schutzkonzeptPreview.childImageSharp.fixed}
              objectFit={"contain"}
            />
          </a>
        </FilePreview>
        <a href={"/schutzkonzept.pdf"} target={"_blank"}>
          Schutzkonzept.pdf
        </a>

        <hr />

        <h2>Kontakt zu Fachberatungsstellen</h2>
        <p>
          Für Fälle sexualisierter Gewalt und für viele andere Sorgen gibt es
          professionellen Rat, den du jederzeit einholen kannst. In deiner
          Region findest du Rat bei folgenden Fachberatungsstellen:
        </p>
        <h3>Worms:</h3>
        <ul>
          <li>
            Kinderschutzdienst Worms (ASB), Judengasse 26,
            <a
              className={"tel"}
              href={"tel:0624188917"}
              rel={"noopener noreferrer"}
            >
              ☎ 06241-88917
            </a>
            ,
            <a
              href={"mailto:kinderschutzdienst@asb-worms.de"}
              rel={"noopener noreferrer"}
            >
              ✉ kinderschutzdienst@asb-worms.de
            </a>
          </li>
        </ul>
        <h3>Pfalz:</h3>
        <ul>
          <li>
            Wildwasser Ludwigshafen, Falkenstraße 17,
            <a
              className={"tel"}
              href={"tel:0621628165"}
              rel={"noopener noreferrer"}
            >
              ☎ 0621-628165
            </a>
            ,
            <a
              target={"_blank"}
              href={"http://www.wildwasser-ludwigshafen.de"}
              rel={"noopener noreferrer"}
            >
              https://www.wildwasser-ludwigshafen.de
            </a>
          </li>
          <li>
            SOS-Familienhilfezentrum Kaiserslautern, Rudolf-Breitscheid-Straße
            42,
            <a
              className={"tel"}
              href={"tel:0631316440"}
              rel={"noopener noreferrer"}
            >
              ☎ 0631-316440
            </a>
            ,
            <a
              target={"_blank"}
              href={
                "https://www.sos-kinderdorf.de/familienhilfezentrum-kaiserslautern"
              }
              rel={"noopener noreferrer"}
            >
              https://www.sos-kinderdorf.de/familienhilfezentrum-kaiserslautern
            </a>
          </li>
        </ul>
        <h3>Mainz:</h3>
        <ul>
          <li>
            Kinderschutz-Zentrum Mainz, Lessingstraße 25,
            <a
              className={"tel"}
              href={"tel: 06131613737"}
              rel={"noopener noreferrer"}
            >
              ☎ 06131-613737
            </a>
            ,
            <a
              href={"mailto: info@kinderschutzzentrum-mainz.de"}
              rel={"noopener noreferrer"}
            >
              ✉ info@kinderschutzzentrum-mainz.de
            </a>
          </li>
          <li>
            Kinderschutzbund Nieder-Olm, Domherrnstr. 3,
            <a
              className={"tel"}
              href={"tel:061361314"}
              rel={"noopener noreferrer"}
            >
              ☎ 06136-13 14
            </a>
            ,
            <a
              target={"_blank"}
              href={"https://www.kinderschutzbund-mainz.de"}
              rel={"noopener noreferrer"}
            >
              https://www.kinderschutzbund-mainz.de
            </a>
          </li>
        </ul>
        <h3>Überall erreichbar:</h3>
        <ul>
          <li>
            <a
              target={"_blank"}
              href={"https://www.hilfeportal-missbrauch.de"}
              rel={"noopener noreferrer"}
            >
              https://www.hilfeportal-missbrauch.de
            </a>
          </li>
          <li>
            Nationale Infoline, Netzwerk und Anlaufstelle sexuelle Gewalt
            (N.I.N.A.),{" "}
            <a
              className={"tel"}
              href={"tel:08002255530"}
              rel={"noopener noreferrer"}
            >
              ☎ 0800-2255530
            </a>
            ,
            <a
              target={"_blank"}
              href={"https://www.nina-info.de"}
              rel={"noopener noreferrer"}
            >
              https://www.nina-info.de
            </a>
          </li>
        </ul>
      </InfoSection>
    </Layout>
  )
}

export const query = graphql`
  query {
    till: file(relativePath: { eq: "ansprechpersonen/till.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    louise: file(relativePath: { eq: "ansprechpersonen/louise.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 10000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    inge: file(relativePath: { eq: "ansprechpersonen/inge.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    schutzkonzeptPreview: file(
      relativePath: { eq: "ansprechpersonen/schutzkonzept-preview.png" }
    ) {
      childImageSharp {
        fixed(width: 400, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
